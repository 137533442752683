import * as React from 'react';
import Helmet from 'react-helmet';
import { Container } from 'react-bootstrap';

import { Footer } from '../Footer';

const TemplateWrapper: React.SFC<{
  title: string;
}> = ({ title, children }) => (
  <React.Fragment>
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content:
            'Slew Systems is a design and software development consulting company.',
        },
        {
          name: 'keywords',
          content: 'slew systems',
        },
      ]}
    >
      <meta property="og:site_name" content="Slew Systems LLC" />
      <meta property="og:title" content="Slew Systems LLC" />
      {/* <meta property="og:image" content="http://www.slewsystems.com/ssthumb.png" /> */}
      <meta property="og:url" content="http://slewsystems.com" />
    </Helmet>
    <Container fluid={true} style={{ marginTop: '2em' }}>
      {children}
      <Footer />
    </Container>
  </React.Fragment>
);

export default TemplateWrapper;
