import * as React from 'react';
import { Nav, Navbar, Container, Row, Col } from 'react-bootstrap';

// tslint:disable-next-line: no-submodule-imports
import {
  FaGitlab,
  FaGithub,
  FaTwitter,
  FaFacebook,
  FaWordpress,
} from 'react-icons/fa';

export const Footer: React.SFC<{}> = () => (
  <Container as="footer">
    <Row>
      <Col xs="12">
        <Nav className="justify-content-center">
          {/* <Nav.Item>
                        <Nav.Link href="https://gitlab.com/slewsystems" target="_blank" title="Gitlab">
                            <FaGitlab />
                        </Nav.Link>
                    </Nav.Item> */}
          <Nav.Item>
            <Nav.Link
              href="https://github.com/slewsystems"
              target="_blank"
              title="Github"
            >
              <FaGithub />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="https://twitter.com/slewsystems"
              target="_blank"
              title="Twitter"
            >
              <FaTwitter />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="https://facebook.com/slewsystems"
              target="_blank"
              title="Facebook"
            >
              <FaFacebook />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="https://blog.slewsystems.com/"
              target="_blank"
              title="Development Blog"
            >
              <FaWordpress />
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
    <Row>
      <Col xs="12" as="p" className="text-center" style={{ fontSize: '.8em' }}>
        Copyright {new Date().getFullYear()}{' '}
        <a href="https://slewsystems.com" target="_blank">
          Slew Systems LLC
        </a>
      </Col>
    </Row>
  </Container>
);
